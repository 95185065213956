/** 专利列表 */
<template>
  <div class="patentmark-content">
    <Filters v-if="Object.keys(filters).length" :filters="filters" @selectFilter="selectFilter" />
    <AssetsList v-if="patentData" assetsType="patent" :listData="patentData" :page="filtersData.page" :total="pageTotal"
      :pageSize="10" :isMaxTotal="true" @setAssetsSort="setAssetsSort" @changePage="changePage">
      <template v-slot:default='{item}'>
        <router-link class="flex flex-1" target="_blank" :to="{path:'/patent-details',query:{pid:item.pid}}">
          <el-image class="assets-img border-r6 mr-22" :src="item.IMGNAME" fit="contain"> </el-image>
          <div class="flex-column justify-around assets-info flex-1">
            <h4 class="assets-title align-center">
              <span class="text-ellipsis">{{item.tio}}</span>
              <el-tag v-if="item.pdt" class="ml-10"> {{item.pdt}} </el-tag>
              <el-tag v-if="item.lsscn" class="ml-10"> {{item.lsscn}} </el-tag>
            </h4>
            <el-row>
              <el-col :span="8">申请号：{{item.ano}}</el-col>
              <el-col :span="8">申请日：{{item.ad}}</el-col>
              <el-col :span="8">申请人：{{item.apo}}</el-col>
              <el-col :span="8">公开（公告）号：{{item.pno}}</el-col>
              <el-col :span="8">公开（公告）日：{{item.pd}}</el-col>
              <el-col :span="8" v-if="item.ipc">IPC分类：{{item.ipc}}</el-col>
            </el-row>
            <p class="assets-text text-ellipsis" v-if="item.absc">摘要：{{item.absc}}</p>
          </div>
        </router-link>
      </template>
    </AssetsList>
  </div>
</template>
<script>
import Filters from '@/components/Filters.vue'
import AssetsList from '@/components/Assets/list.vue'
import { getPatentFilters, getPatentList } from '@/api/patent'
export default {
  components: {
    Filters,
    AssetsList
  },
  data () {
    return {
      filters: {},//筛选数据合集
      filtersRd: [
        { value: 1, nc_cn: '1年以内' },
        { value: 2, nc_cn: '1年' },
        { value: 3, nc_cn: '2年' },
        { value: 4, nc_cn: '3~5年' },
        { value: 5, nc_cn: '5~6年' },
        { value: 6, nc_cn: '6~10年' },
        { value: 7, nc_cn: '10年以上' },
      ],
      patentData: null,
      pageTotal: 0,
      filtersDataDef: {
        keywords: this.$route.query.search_val,
        page: 1,
        sort: '-AD'
      },
      filtersData: {},// 获取数据--条件
    }
  },
  mounted () {
    this.filtersData = this.filtersDataDef
    this.getPatentFilters()
    this.getPatentList()
  },
  methods: {
    // 获取专利筛选条件
    async getPatentFilters () {
      try {
        let { data } = await getPatentFilters(this.$route.query.search_val)
        if (data && Object.keys(data).length) {
          this.filters = { ...data, rd: this.filtersRd }
        }
      } catch (error) {
        return false
      }
    },
    // 获取专利列表
    async getPatentList () {
      try {
        let { data, status } = await getPatentList(this.filtersData)
        if (status == 200) {
          this.patentData = data.data
          this.pageTotal = data.total *= 1
          // 给数组每一项添加新字段
          this.patentData.forEach(item => {
            this.$set(item, 'monitor_load', false)
            this.$set(item, 'monitor_annualCost_load', false)
          })
        }
      } catch (error) {

      }
    },
    // 筛选
    selectFilter (selectFilter) {
      this.filtersData.page = 1
      this.filtersData = { ...this.filtersDataDef, ...selectFilter }
      this.getPatentList()
    },
    // 排序
    setAssetsSort (sort) {
      this.filtersData.sort = sort
      this.filtersDataDef.sort = sort
      this.getPatentList()
    },
    // 分页切换
    changePage (page) {
      this.filtersData.page = page
      this.getPatentList()
    }
  },
}
</script>